import * as React from "react"
import { Link, graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Row from "react-materialize/lib/Row"
import { Col } from "react-materialize"

const Blog = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <div className="page-container services">
          <h2 className="page-header color-0 center">Notre Blog</h2>
          <section id="app-dev">
            <div className="container2">
              <p className="color-1">
                No blog posts have been created yet.
              </p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }

  return (
    <Layout >
      <SEO title="All posts" />
      <div className="page-container services">
        <h2 className="page-header color-0 center">Notre Blog</h2>
        <section id="app-dev">
          <div className="container2">
            <p className="section-body2 color-1">
              Bienvenue sur notre blog
              <span role="img" aria-labelledby="handshake">🤝</span>! Ici nous publions des articles sur les technologies que nous utilisons et sur la science en general.
            </p>
            <Row>
              {posts.map(post => {
                const title = post.frontmatter.title || post.fields.slug

                return (
                  <Col l={6} m={6} s={12} key={post.fields.slug} className="post-pad">
                    <Link to={post.fields.slug} itemProp="url">
                      <div className="mycard color-4-bg">
                        <article
                          className="post-list-item"
                          itemScope
                          itemType="http://schema.org/Article"
                        >
                          <div>
                            <h2 className="color-1">{title}</h2>
                            <small className="color-0">{post.frontmatter.tag}</small>
                          </div>
                          <div className="post-card-desc">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: post.excerpt,
                              }}
                              itemProp="description"
                              className="color-1"
                            />
                          </div>
                        </article>
                      </div>
                    </Link>
                  </Col>
                )
              })}
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          tag
          title
          description
        }
      }
    }
  }
`
